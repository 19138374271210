import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../layouts';

import { Footer, Header, Menu, SEO } from '../../components';

import * as S from './styles';

const PageTemplate = ({ data }) => (
  <Layout>
    <>
      <SEO title={data.wpPage.title} description={data.wpPage.content} />
      <Header />
      <Menu />

      <S.Content>
        <S.Title>{data.wpPage.title}</S.Title>

        <S.PageContent dangerouslySetInnerHTML={{ __html: data.wpPage.content }} />
      </S.Content>
      <Footer />
    </>
  </Layout>
);

export default PageTemplate;
export const result = graphql`
  query($id: Int!) {
    wpPage(databaseId: { eq: $id }) {
      content
      title
    }
  }
`;
