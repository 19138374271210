import styled from 'styled-components';
import { media } from '../../styles/media-queries';

export const Content = styled.main`
  margin: 60px auto 0;
  max-width: ${(props) => props.theme.grid.container};
  width: 100%;

  ${media.mobile`
    margin-top: 30px;
  `}
`;

export const Title = styled.h1`
  color: ${(props) => props.theme.palette.black};
  font-size: 40px;
  line-height: 29px;
  margin-bottom: 40px;
  padding: 0 15px 15px;
  text-align: center;
`;

export const PageContent = styled.div`
  color: ${(props) => props.theme.palette.black};
  margin-bottom: 60px;

  blockquote {
    background: #f9f9f9;
    border-left: 10px solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 10px;

    p {
      display: inline;
    }

    &:before {
      color: #ccc;
      content: open-quote;
      font-size: 4em;
      line-height: 0.1em;
      margin-right: 0.25em;
      vertical-align: -0.4em;
    }
  }

  figure {
    margin: 30px 0;
    text-align: center;

    > img {
      display: block;
      height: auto;
      margin: 0 auto;
      max-width: 100%;
    }

    figcaption {
      font-size: 13px;
      line-height: 18px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    margin-bottom: 15px;
  }

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 25px;
  }

  h4 {
    font-size: 23px;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 18px;
  }

  p {
    font-size: 17px;
    line-height: 28px;
    margin: 15px 0;
  }

  a {
    color: ${(props) => props.theme.palette.black};
    display: inline-block;
    position: relative;
    transition: width 0.3s linear 0.3s;

    &:after {
      background-color: ${(props) => props.theme.palette.black};
      color: ${(props) => props.theme.palette.white};
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 0;
      transition: width 0.3s linear;
      z-index: -2;
    }

    &:hover {
      color: ${(props) => props.theme.palette.white};

      &:after {
        width: 100%;
      }
    }
  }

  ul {
    padding: 15px 15px 10px;

    li {
      margin-bottom: 15px;
    }
  }

  ${media.mobile`
    padding: 15px;
  `}

  ${media.tablet`
    padding: 15px;
  `}

  ${media.desktop`
    margin: 0 auto;
    width: 70%;
  `}
`;
